<!-- Advanced -->
<section id="advanced">
    <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
        <h3 class="toggle-fieldset" (click)="showAdvanced = !showAdvanced" [ngClass]="[!showAdvanced ? 'closed' : '']">
            {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
            ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
        </h3>
    </div>
    <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
        <!-- Billing Code -->
        <div *ngIf="showBilling">
            <div class="form-group">
                <label for="billing_code" [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }"
                    >{{ "BILLING_CODE" | translate
                    }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                ></label>
                <input
                    type="text"
                    autocomplete="cc-csc"
                    id="billing_code"
                    name="billing_code"
                    placeholder="{{ 'BILLING_CODE' | translate }}"
                    class="form-control form-control-sm"
                    [(ngModel)]="model.billing_code"
                    [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }"
                    #billing_code="ngModel"
                    (change)="this.onChange()"
                />
            </div>
            <!-- Billing password -->
            <div class="form-group">
                <label for="billing_password" [ngClass]="{ 'is-invalid': billing_password.errors }"
                    >{{ "BILLING_PASSWORD" | translate
                    }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                ></label>
                <input
                    type="password"
                    autocomplete="cc-csc"
                    id="billing_password"
                    name="billing_password"
                    placeholder="{{ 'BILLING_PASSWORD' | translate }}"
                    class="form-control form-control-sm"
                    [(ngModel)]="model.billing_password"
                    [ngClass]="{ 'is-invalid': billing_password.errors }"
                    #billing_password="ngModel"
                    (change)="this.onChange()"
                />
                <div *ngIf="billing_password.invalid" class="invalid-feedback">
                    <div *ngIf="billing_password.errors.validationFailed">
                        {{ "BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                    </div>
                    <div *ngIf="billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                </div>
                <zx-billing-codes-check [codeControl]="billing_code" [passControl]="billing_password"></zx-billing-codes-check>
            </div>
        </div>
        <!-- Autopull Billing Code -->
        <div *ngIf="showAutopullBilling">
            <div class="form-group">
                <label for="autopull_billing_code" [ngClass]="{ 'is-invalid': form.submitted && autopull_billing_code.errors }"
                    >{{ "AUTOPULL_BILLING_CODE" | translate
                    }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                ></label>
                <input
                    type="text"
                    autocomplete="cc-csc"
                    id="autopull_billing_code"
                    name="autopull_billing_code"
                    placeholder="{{ 'AUTOPULL_BILLING_CODE' | translate }}"
                    class="form-control form-control-sm"
                    [(ngModel)]="model.autopull_billing_code"
                    [ngClass]="{ 'is-invalid': form.submitted && autopull_billing_code.errors }"
                    #autopull_billing_code="ngModel"
                    [disabled]="model.disable_autopull"
                    (change)="this.onChange()"
                />
            </div>
            <!-- Autopull Billing password -->
            <div class="form-group">
                <label for="autopull_billing_password" [ngClass]="{ 'is-invalid': autopull_billing_password.errors }"
                    >{{ "AUTOPULL_BILLING_PASSWORD" | translate
                    }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
                ></label>
                <input
                    type="password"
                    autocomplete="cc-csc"
                    id="autopull_billing_password"
                    name="autopull_billing_password"
                    placeholder="{{ 'AUTOPULL_BILLING_PASSWORD' | translate }}"
                    class="form-control form-control-sm"
                    [(ngModel)]="model.autopull_billing_password"
                    [ngClass]="{ 'is-invalid': autopull_billing_password.errors }"
                    #autopull_billing_password="ngModel"
                    [disabled]="model.disable_autopull"
                    (change)="this.onChange()"
                />
                <div *ngIf="autopull_billing_password.invalid" class="invalid-feedback">
                    <div *ngIf="autopull_billing_password.errors.validationFailed">
                        {{ "AUTOPULL_BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                    </div>
                    <div *ngIf="autopull_billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                </div>
                <zx-billing-codes-check
                    [disabled]="model.disable_autopull"
                    [codeControl]="autopull_billing_code"
                    [passControl]="autopull_billing_password"
                ></zx-billing-codes-check>
            </div>
        </div>
        <!-- MTU field -->
        <div *ngIf="showMTUField" class="form-group">
            <label for="MTU_INPUT" [ngClass]="{ 'is-invalid': form.submitted && MTU_INPUT.errors }"
                >{{ "MTU" | translate
                }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
            ></label>
            <input
                type="number"
                autocomplete="cc-csc"
                id="MTU_INPUT"
                name="MTU_INPUT"
                placeholder="{{ 'MTU' | translate }}"
                class="form-control form-control-sm"
                [(ngModel)]="model.mtu"
                [ngClass]="{ 'is-invalid': form.submitted && MTU_INPUT.errors }"
                #MTU_INPUT="ngModel"
                (change)="this.onChange()"
            />
        </div>
        <!-- Auto Pull MTU  -->
        <div *ngIf="showAutoMTU" class="form-group">
            <label for="AUTOPULL_MTU" [ngClass]="{ 'is-invalid': form.submitted && AUTOPULL_MTU.errors }">
                {{ "AUTO_PULL_MTU" | translate }} [{{ "BYTES" | translate | lowercase }}]
                <fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
            ></label>
            <input
                type="number"
                autocomplete="cc-csc"
                id="AUTOPULL_MTU"
                name="AUTOPULL_MTU"
                placeholder="{{ 'MTU' | translate }}"
                class="form-control form-control-sm"
                [(ngModel)]="model.autopull_mtu"
                [ngClass]="{ 'is-invalid': form.submitted && AUTOPULL_MTU.errors }"
                #AUTOPULL_MTU="ngModel"
                (change)="this.onChange()"
            />
        </div>
        <div *ngIf="showTranscodeEncoder" class="form-group">
            <label for="transcodeEncoder"
                >{{ "ENCODER" | translate
                }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
            ></label>
            <ng-select
                id="transcodeEncoder"
                name="transcodeEncoder"
                [items]="transcodeEncoderOptions"
                [clearable]="false"
                bindValue="id"
                bindLabel="name"
                placeholder="{{ 'SELECT_ENCODER' | translate }}"
                [(ngModel)]="model.transcode_encoder"
                class="form-control"
                #transcodeEncoder="ngModel"
                (change)="this.onChange()"
            >
            </ng-select>
        </div>
        <!-- Transcoding Threads -->
        <div class="form-group" *ngIf="showTranscodeEncoder && model.transcode_encoder === 'x264'">
            <label for="transcodeThreads"
                >{{ "THREADS" | translate
                }}<fa-icon *ngIf="isEdit" icon="redo" size="sm" [ngbTooltip]="RestartContent" triggers="hover click" [closeDelay]="500"></fa-icon
            ></label>
            <input
                type="number"
                [min]="0"
                [max]="16"
                id="transcode_threads"
                name="transcode_threads"
                placeholder="{{ 'THREADS' | translate }} "
                class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': form.submitted && transcode_threads.errors }"
                [(ngModel)]="model.transcode_threads"
                #transcode_threads="ngModel"
            />
            <div *ngIf="transcode_threads.invalid" class="invalid-feedback">{{ "THREADS" | translate }} {{ "MUST_BE_BETWEEN_0_AND_16" | translate }}.</div>
        </div>
        <div *ngIf="showInputDeviceBind" class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="bind_input_device"
                    name="bind_input_device"
                    [(ngModel)]="model.bind_input_device"
                    (change)="this.onChange()"
                />
                <label class="form-check-label" for="bind_input_device">{{ "BIND_INPUT_NIC_SELECTION" | translate }}</label>
            </div>
        </div>
        <!-- Frozen Video Minimal Duration  -->
        <div *ngIf="showFrozenVideoMinimalDuration" class="form-group">
            <label class="" for="freeze_detection_timeout_sec"
                >{{ "FROZEN_VIDEO_MINIMAL_DURATION" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label
            >
            <input
                type="number"
                autocomplete="cc-csc"
                placeholder="{{ 'USE_BROADCASTER_DEFAULT_VALUE' | translate }}"
                class="form-control form-control-sm"
                id="freeze_detection_timeout_sec"
                name="freeze_detection_timeout_sec"
                [(ngModel)]="model.freeze_detection_timeout_sec"
                (change)="this.onChange()"
            />
            <small>
                {{ "BROADCASTER_16.3_IGNORE" | translate }}
            </small>
        </div>

        <!-- EXTERNAL_ID -->
        <div *ngIf="showExternalID" class="form-group">
            <label for="EXTERNAL_ID" [ngClass]="{ 'is-invalid': form.submitted && EXTERNAL_ID.errors }">{{ "EXTERNAL_ID" | translate }}</label>
            <input
                type="string"
                autocomplete="cc-csc"
                id="EXTERNAL_ID"
                name="EXTERNAL_ID"
                placeholder="{{ 'EXTERNAL_ID' | translate }}"
                class="form-control form-control-sm"
                [(ngModel)]="model.external_id"
                [ngClass]="{ 'is-invalid': form.submitted && EXTERNAL_ID.errors }"
                #EXTERNAL_ID="ngModel"
                (change)="this.onChange()"
            />
        </div>
        <!-- Blank Video Minimal Duration  -->
        <div *ngIf="showBlancVideoMinimalDuration" class="form-group">
            <label class="" for="blank_detection_timeout_sec">{{ "BLANK_VIDEO_MINIMAL_DURATION" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label>
            <input
                type="number"
                autocomplete="cc-csc"
                placeholder="{{ 'USE_BROADCASTER_DEFAULT_VALUE' | translate }}"
                class="form-control form-control-sm"
                id="blank_detection_timeout_sec"
                name="blank_detection_timeout_sec"
                [(ngModel)]="model.blank_detection_timeout_sec"
                (change)="this.onChange()"
            />
            <small>
                {{ "BROADCASTER_16.3_IGNORE" | translate }}
            </small>
        </div>
        <!-- Force I Frame on SCTE -->
        <div *ngIf="showForceIFrames" class="form-group">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="force_i_frame" name="force_i_frame" [(ngModel)]="model.force_i_frame_on_scte" />
                <label class="form-check-label" for="force_i_frame"
                    >{{ "FORCE_I_FRAME_ON_SCTE" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                </label>
            </div>
        </div>
        <!-- HTML Overlay URL -->
        <ng-container *ngIf="showHtmlOverlayUrl">
            <div class="form-group">
                <label for="html_overlay_url" [ngClass]="{ 'is-invalid': form.submitted && html_overlay_url.errors }">{{
                    "HTML_OVERLAY_URL" | translate
                }}</label>
                <input
                    type="text"
                    id="html_overlay_url"
                    name="html_overlay_url"
                    placeholder="{{ 'HTML_OVERLAY_URL' | translate }}"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': form.submitted && html_overlay_url.errors }"
                    [(ngModel)]="model.html_overlay_url"
                    pattern="^(http|https):\/\/.*"
                    #html_overlay_url="ngModel"
                    (change)="this.onChange()"
                />
                <div *ngIf="html_overlay_url.invalid" class="invalid-feedback">
                    <div *ngIf="html_overlay_url.errors.pattern">{{ "INVALID_URL" | translate }}.</div>
                </div>
            </div>
            <!-- Transcode TSID -->
            <div class="form-group">
                <label for="transcode_tsid" translate>TRANSCODE_TSID</label>
                <input
                    type="number"
                    id="transcode_tsid"
                    name="transcode_tsid"
                    placeholder="{{ 'TRANSCODE_TSID' | translate }}"
                    class="form-control form-control-sm"
                    [(ngModel)]="model.transcode_tsid"
                    (change)="this.onChange()"
                    #transcode_tsid="ngModel"
                />
            </div>
        </ng-container>
    </fieldset>
</section>
