import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber, interval, Subject, of, firstValueFrom, lastValueFrom } from "rxjs";
import { share, map, takeUntil, repeatWhen, catchError, takeWhile } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { SharedService } from "../../services/shared.service";
import {
    Source,
    TraceRoute,
    Tag,
    APIResponse,
    HLSPlaylistStreams,
    FailoverSource,
    WebRTCInitResponse,
    WebRTCConnectResponse,
    FrontSourceType,
    MediaConnectSource,
    RecoveryState
} from "../../models/shared";
import { AuthService } from "src/app/services/auth.service";
import { ChannelTypes } from "../channels/channel";

import * as _ from "lodash";
import moment from "moment";
import { ChannelsService } from "../channels/channels.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SourceConfigDialogComponent } from "src/app/pages/sources/source/source-config-dialog/source-config-dialog.component";
import { SourceTracerouteDialogComponent } from "./source/source-traceroute-dialog/source-traceroute-dialog.component";

export interface SourcesPagination {
    pages: number;
    loaded: number;
}

export type Bitrate = {
    date: Date;
    kbs: number | null;
};
export type Pid = {
    name: string;
    bitrates: Bitrate[];
};

export type Thumb = {
    id: number;
    thumbnail: HttpResponse<ArrayBuffer>;
};

export class SourceDependencyError extends Error {
    constructor(error: string) {
        super(error);
        this.customError = error;
    }
    customError: string; //  Compatibility with multiple-confirm-dialog.component.ts
}
export enum SOURCE_CONTENT_ANALYSIS {
    FULL = 0,
    TR101_ONLY = 1,
    NONE = 2
}

@Injectable({
    providedIn: "root"
})
export class SourcesService {
    sources: Observable<Source[]>;
    traceroute: Observable<TraceRoute>;
    pagination: Observable<SourcesPagination>;

    initialized = new Subject<boolean>();

    private sources$: ReplaySubject<Source[]>;
    private traceroute$: ReplaySubject<TraceRoute>;
    private pagination$: ReplaySubject<SourcesPagination>;
    private dataStore: {
        sources: Source[];
        traceroute: TraceRoute;
        pagination: SourcesPagination;
    };

    thumbnails: Observable<Thumb[]>;
    private thumbnails$: ReplaySubject<Thumb[]>;
    private thumbnailStore: {
        thumbnails: Thumb[];
    };

    private lastSourcesRefresh: number;

    fullSourcesLoaded = false;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private sharedService: SharedService,
        private channelsService: ChannelsService,
        private ngbModal: NgbModal
    ) {
        this.reset();

        // heartbeat update
        interval(10000)
            .pipe(
                takeUntil(this.authService.logoutEvent),
                repeatWhen(() => this.authService.loginEvent)
            )
            .subscribe(() => {
                this.refreshSources(false);
            });

        this.authService.logoutEvent.subscribe(() => {
            this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            sources: [],
            traceroute: {},
            pagination: null
        };

        this.thumbnailStore = {
            thumbnails: []
        };
        this.thumbnails$ = new ReplaySubject<[]>(1);
        this.thumbnails = this.thumbnails$.asObservable();

        this.lastSourcesRefresh = null;

        this.sources$ = new ReplaySubject<Source[]>(1);
        this.traceroute$ = new ReplaySubject<TraceRoute>(1);
        this.pagination$ = new ReplaySubject<SourcesPagination>(1);
        this.sources = this.sources$.asObservable();
        this.traceroute = this.traceroute$.asObservable();
        this.pagination = this.pagination$.asObservable();
    }

    public sourceType(source: Source): FrontSourceType {
        switch (source.type) {
            case "file":
                return "File";
            case "monitor_only":
                return "Monitor Only";
            case "intercluster":
                return "Inter-Cluster";
            case "ndi":
                return "NDI";
            case "hitless": {
                const mergeMode =
                    source.merge_mode === "content"
                        ? " (Hitless)"
                        : source.merge_mode === "rtp"
                        ? " (SMPTE 2022-7)"
                        : "";
                return `Failover${mergeMode}`;
            }
            case "transcoded":
                return "Transcoded";
            case "udp":
                return "UDP";
            case "rtp":
                return "RTP";
            case "srt":
                return "SRT";
            case "rist":
            case "rist_push":
            case "rist_pull":
                return "RIST";
            case "pid_map":
                return "PID Mapping";
            case "feeder":
                return "Feeder";
            case "broadcaster":
                return "Broadcaster";
            case "zec":
                return "ZEC";
            case "zixi_pull":
                return "Pull";
            case "zixi_push":
                return "Push";
            case "other":
                return "Other";
            case "mediaconnect":
                return "From MediaConnect";
            case "rtmp":
                return "RTMP";
            case "hls_pull":
                return "HLS";
            case "demux":
                return "Demux";
            case "multiview":
                return "Multiview";
            case "multiplex":
                return "Multiplex";
            default:
                return "Zixi";
        }
    }

    public getRoutingSourceType(source: Source): string {
        let type = "zixi";
        switch (source.type) {
            case "file":
            case "monitor_only":
            case "ndi":
            case "hitless":
            case "transcoded":
            case "multiview":
            case "demux":
            case "multiplex":
            case "hls_pull":
            case "srt":
            case "rtmp":
                type = source.type;
                break;
            case "rist":
            case "rist_push":
            case "rist_pull":
                type = "rist";
                break;
            case "pid_map":
                type = "pid-mapping";
                break;
            case "udp":
            case "rtp":
                type = "udp-rtp";
                break;
            case "intercluster":
                type = "inter-cluster";
                break;
        }
        return type;
    }

    public isPushOrPull(source: Source) {
        return [
            "Inter-Cluster",
            "Feeder",
            "Broadcaster",
            "ZEC",
            "Pull",
            "Push",
            "Other",
            "From MediaConnect",
            "Zixi"
        ].includes(source._frontData.typeColumn);
    }

    public prepSource(source: Source, override: boolean, fromGet: boolean) {
        if (!source) return;

        const existingSource = _.find(this.dataStore.sources, s => {
            return s.id === source.id;
        });

        if (existingSource && existingSource._frontData) {
            source._frontData = existingSource._frontData;
        } else {
            source._frontData = {
                sortableStatus: "",
                sortableCluster: ""
            };
        }

        if (fromGet) source.lastRefresh = moment();
        else if (!source.lastRefresh) source.lastRefresh = moment.invalid();

        source.zixi = true;
        source.mediaconnect = false;

        // Override
        if (
            !override &&
            existingSource &&
            existingSource.status &&
            existingSource.generalStatus === source.generalStatus
        ) {
            source.status = _.merge(existingSource.status, source.status);
            source.health = _.merge(existingSource.health, source.health);
        }

        // Sortable Cluster
        if (source.inputCluster) {
            source._frontData.sortableCluster = source.inputCluster.name;
        }

        this.sharedService.prepStatusSortFields(source);

        // isConnected
        const offlineError = (source.activeStates || []).find(as => as.type === "error" && as.group === "offline");
        source._frontData.isConnected = source && !offlineError;

        if (["udp", "rtp", "rist", "srt", "zixi_pull", "rtmp", "hls_pull"].includes(source.protocol)) {
            if (["rist_pull"].includes(source.type) || (source.type === "srt" && source.srt_mode === "pull")) {
                source._frontData.input_description = `${source.remote_host}:${source.remote_port}`;
            } else if (source.type === "zixi_pull")
                source._frontData.input_description = [
                    source.remote_host,
                    ":",
                    source.remote_port ? source.remote_port : 2088,
                    "/",
                    source.input_id
                ].join("");
            else if (source.protocol === "rtmp") {
                if (source.pull_mode) source._frontData.input_description = `${source.url}/${source.input_id}`;
            } else if (source.protocol === "hls_pull") {
                source._frontData.input_description = source.url;
            } else source._frontData.input_description = `:${source.listening_port}`;
        }

        // Content Analysis
        if (source.status && source.status.tr101 && source.status.tr101.status) {
            source._frontData.contentAnalysis = "";
            if (source.status.tr101.status.p1_ok === 1) source._frontData.contentAnalysis += "1";
            else source._frontData.contentAnalysis += "0";
            if (source.status.tr101.status.p2_ok === 1) source._frontData.contentAnalysis += "1";
            else source._frontData.contentAnalysis += "0";
        } else {
            source._frontData.contentAnalysis = "0";
        }

        for (const fs of source.failoverSources ?? []) {
            fs.is_active = source.status?.compound_components?.find(cc =>
                fs.source.monitor_only ? cc.id === fs.source?.name : cc.id === fs.source?.stream_id
            )?.is_active;
        }

        // Corrected Type for use in Source List Type column
        source._frontData.typeColumn = this.sourceType(source);

        if (source.resourceTags)
            source.resourceTags.sort((a: Tag, b: Tag) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        return source;
    }

    private updateStore(source: Source, merge: boolean, fromGet = false): void {
        this.prepSource(source, !merge, fromGet);

        const currentIndex = this.dataStore.sources.findIndex(g => g.id === source.id);
        if (currentIndex === -1) {
            this.dataStore.sources.push(source);
            return;
        } else if (merge) {
            const current = this.dataStore.sources[currentIndex];
            Object.assign(current, source);
        } else {
            this.dataStore.sources[currentIndex] = source;
        }
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    refreshSources(summary: boolean, force?: boolean, ids?: number[]): Observable<Source[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && this.lastSourcesRefresh && _.now() - this.lastSourcesRefresh <= 60000) return this.sources;
        this.lastSourcesRefresh = _.now();

        const isUpdate = !summary;
        const sources$ = this.getSourcesNew(ids, summary, isUpdate, false).pipe(takeWhile(sources => !!sources));
        sources$.subscribe(sources => {
            (ids || []).forEach(existingId => {
                const newIndex = sources.findIndex(s => s.id === existingId);
                if (newIndex === -1) {
                    const existingIndex = this.dataStore.sources.findIndex(s => s.id === existingId);
                    this.dataStore.sources.splice(existingIndex, 1);
                }
            });

            sources.forEach(s => this.updateStore(s, true));

            this.sources$.next(Object.assign({}, this.dataStore).sources);
        });

        return sources$;
    }

    loadSourcesSuperFast() {
        if (this.dataStore.sources && this.dataStore.sources.length > 0) return this.sources$;

        const sources$ = this.getSourcesNew(null, true).pipe(takeWhile(sources => !!sources));

        sources$.subscribe(sources => {
            sources.forEach(s => this.updateStore(s, true));

            this.sources$.next(Object.assign({}, this.dataStore).sources);
        });

        return sources$;
    }

    loadSourcesPaginated(): Observable<Source[]> {
        if (this.fullSourcesLoaded) return this.sources$;
        this.fullSourcesLoaded = true;
        return this.loadSourcesPage(1);
    }

    private loadSourcesPage(page: number): Observable<Source[]> {
        const sources$ = this.http
            .get<{ success: boolean; result: Source[]; pages: number }>(
                Constants.apiUrl + Constants.apiUrls.source + "?fast=1" + "&page=" + page
            )
            .pipe(share());

        sources$.subscribe(
            data => {
                const sources = data.result;
                const pages = data.pages;

                sources.forEach(s => this.updateStore(s, true));

                this.sources$.next(Object.assign({}, this.dataStore).sources);

                this.dataStore.pagination = {
                    pages: pages
                        ? pages
                        : this.dataStore.pagination && this.dataStore.pagination.pages
                        ? this.dataStore.pagination.pages
                        : 1,
                    loaded: page
                };
                this.pagination$.next(Object.assign({}, this.dataStore).pagination);

                if (this.dataStore.pagination.pages > page) this.loadSourcesPage(page + 1);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SOURCES"), error)
        );

        return sources$.pipe(map(r => r.result));
    }

    refreshSource(s: Source | number, force?: boolean): Observable<Source> {
        const id: number = typeof s === "number" ? s : s.id;

        if (!force && this.dataStore.sources && this.dataStore.sources.length) {
            const source: Source = this.dataStore.sources.find(f => f.id === id);
            //
            if (source && source.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(source.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<Source>) => {
                        observe.next(source);
                        observe.complete();
                    });
                }
            }
        }

        const source$ = this.http
            .get<{ success: boolean; result: Source }>(Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}`)
            .pipe(share());
        source$.subscribe(
            data => {
                const source = data.result;
                source.hasFullDetails = true;

                // Failover Sources
                _.each(source.failoverSources, failoverSource => {
                    if (!source.status) return;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    failoverSource.source.status = _.find(source.status.compound_components, (componentStatus: any) => {
                        return (
                            componentStatus.id === failoverSource.source.stream_id ||
                            (failoverSource.source.monitor_only && componentStatus.id === failoverSource.source.name)
                        );
                    });
                });

                this.updateStore(source, false, true);

                this.sources$.next(Object.assign({}, this.dataStore).sources);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SOURCE"), error)
        );

        return source$.pipe(map(r => r.result));
    }

    getCachedSource(name?: string, cluster?: string, id?: number) {
        if (this.dataStore.sources && name && cluster)
            return this.dataStore.sources.find(
                source => source.name === name && source.inputCluster.dns_prefix === cluster
            );
        if (this.dataStore.sources && id) return this.dataStore.sources.find(source => source.id === id);
        return undefined;
    }

    getCachedSources(ids: number[]) {
        if (this.dataStore.sources) return this.dataStore.sources.filter(source => ids.includes(source.id));
        return [];
    }

    async addSource(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ result: Source; success: boolean }>(Constants.apiUrl + Constants.apiUrls.source, model)
                .toPromise();
            const source: Source = result.result;

            this.updateStore(source, false);

            this.sources$.next(Object.assign({}, this.dataStore).sources);
            return source;
        } catch (error) {
            return false;
        }
    }

    async updateSource(source: Source, model: Record<string, unknown>): Promise<Source | string | boolean> {
        try {
            const result = await firstValueFrom(
                this.http.put<{ result: Source; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + `${source.id}`,
                    model
                )
            );
            const updatedSource = result.result;
            this.updateStore(updatedSource, true);

            this.sources$.next(Object.assign({}, this.dataStore).sources);
            return updatedSource;
        } catch (error) {
            if (error.status === 428) return true;
            if (error.status === 424) throw new SourceDependencyError(error.error.message);
            else return false;
        }
    }

    async deleteSource(source: Source, overrideDependencies: boolean): Promise<boolean> {
        try {
            const queryParams = overrideDependencies
                ? {
                      override_blocking: true
                  }
                : {};

            const result = await firstValueFrom(
                this.http.delete<{ result: number; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + `${source.id}`,
                    { params: queryParams }
                )
            );

            const deletedId = result.result;
            const sourceIndex = this.dataStore.sources.findIndex(s => s.id === deletedId);
            if (sourceIndex !== -1) this.dataStore.sources.splice(sourceIndex, 1);

            this.sources$.next(Object.assign({}, this.dataStore).sources);
            return true;
        } catch (error) {
            if (error.status === 424) throw new SourceDependencyError(error.error.message);
            return false;
        }
    }

    // Traceroute
    refreshTraceroute(s: Source): Observable<TraceRoute> {
        type TraceAPIResult = { trace: TraceRoute };
        const traceroute$ = this.http
            .get<APIResponse<TraceAPIResult>>(
                Constants.apiUrl + Constants.apiUrls.source + "/" + `${s.id}` + Constants.apiUrls.traceroute
            )
            .pipe(share());

        traceroute$.subscribe(
            data => {
                let traceroute: TraceRoute = data.result.trace;
                if (!s.is_enabled) traceroute = { error: this.translate.instant("SOURCE_DISABLED") };
                else if (!data.success || data.error) traceroute = { error: data.error };
                else this.dataStore.traceroute = traceroute;
                this.traceroute$.next(Object.assign({}, this.dataStore).traceroute);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRACEROUTE"), error)
        );
        return traceroute$.pipe(map(r => r.result.trace));
    }

    // Traceroute
    async resetTR101(s: Source): Promise<boolean> {
        try {
            const result = await this.http
                .put<APIResponse<Source>>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + `${s.id}` + Constants.apiUrls.tr101_reset,
                    {}
                )
                .toPromise();
            const updatedSource = result.result;
            this.updateStore(updatedSource, false);
            this.sources$.next(Object.assign({}, this.dataStore).sources);
            return true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                `sources.service.ts: resetTR101: ${this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRACEROUTE")} : ${
                    error.message
                }`
            );
            return false;
        }
    }

    async toggleComponentSourceLock(failoverSource: FailoverSource, lock: boolean) {
        return this.toggleSourceLock(failoverSource.hitless_failover_source_id, failoverSource.source_id, lock);
    }

    async toggleSourceLock(failoverSourceId: number, sourceId: number, lock: boolean) {
        try {
            const result = await this.http
                .put<{ success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + failoverSourceId + "/components/" + sourceId,
                    { locked: lock ? 1 : 0 }
                )
                .toPromise();

            if (!result.success) return false;

            const currentIndex = this.dataStore.sources.findIndex(g => g.id === failoverSourceId);
            if (currentIndex === -1) return false;

            const current = this.dataStore.sources[currentIndex];

            for (const fs of current.failoverSources || []) {
                fs.locked_source = lock && fs.source_id === sourceId ? 1 : 0;
            }

            this.updateStore(current, true);
            this.sources$.next(Object.assign({}, this.dataStore).sources);
            return true;
        } catch (error) {
            return false;
        }
    }

    // Thumbnail
    async getSourceThumbnail(id: number): Promise<HttpResponse<ArrayBuffer>> {
        return await this.http
            .get(Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}` + "/preview.jpg", {
                headers: new HttpHeaders().append("accept", "image/webp,image/*,*/*;q=0.8"),
                observe: "response",
                responseType: "arraybuffer"
            })
            .pipe(share())
            .toPromise();
    }

    refreshSourceThumbnail(s: Source | number) {
        const id: number = typeof s === "number" ? s : s.id;

        const thumb$ = this.http
            .get(Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}` + "/preview.jpg", {
                headers: new HttpHeaders().append("accept", "image/webp,image/*,*/*;q=0.8"),
                observe: "response",
                responseType: "arraybuffer"
            })
            .pipe(share());

        thumb$.subscribe(
            img => {
                const thumb = img;
                this.updateThumbnailStore(id, thumb);
                this.thumbnails$.next(Object.assign({}, this.thumbnailStore).thumbnails);
            },
            // eslint-disable-next-line no-console
            error => {
                const thumb = null;
                this.updateThumbnailStore(id, thumb);
                this.thumbnails$.next(Object.assign({}, this.thumbnailStore).thumbnails);
            }
        );
    }

    refreshMediaConnectSourceThumbnail(s: MediaConnectSource | number) {
        const id: number = typeof s === "number" ? s : s.id;

        const thumb$ = this.http
            .get(Constants.apiUrl + Constants.apiUrls.mediaconnectSources + `/${id}/thumbnail`, {
                headers: new HttpHeaders().append("accept", "image/jpeg"),
                observe: "response",
                responseType: "arraybuffer"
            })
            .pipe(share());

        thumb$.subscribe(
            img => {
                const thumb = img;
                this.updateThumbnailStore(id, thumb);
                this.thumbnails$.next(Object.assign({}, this.thumbnailStore).thumbnails);
            },
            // eslint-disable-next-line no-console
            error => {
                const thumb = null;
                this.updateThumbnailStore(id, thumb);
                this.thumbnails$.next(Object.assign({}, this.thumbnailStore).thumbnails);
            }
        );
    }

    private updateThumbnailStore(id: number, thumbnail: HttpResponse<ArrayBuffer>): void {
        const currentIndex = this.thumbnailStore.thumbnails.findIndex(g => g.id === id);
        if (currentIndex === -1) {
            this.thumbnailStore.thumbnails.push({ id, thumbnail });
            return;
        } else {
            this.thumbnailStore.thumbnails[currentIndex] = { id, thumbnail };
        }
    }

    async getHLSPlaylistStreams(url: string): Promise<HLSPlaylistStreams> {
        let ret: { result: HLSPlaylistStreams; success: boolean; error: string };
        try {
            ret = await this.http
                .get<{ result: HLSPlaylistStreams; success: boolean; error: string }>(
                    Constants.apiUrl + Constants.apiUrls.source + "/hls_playlist",
                    {
                        params: { url }
                    }
                )
                .pipe(share())
                .toPromise();
        } catch (e) {
            throw new Error(`Failed loading playlist - ${e.status} ${e.statusText}`);
        }

        if (!ret.success) throw new Error(ret.error);
        return ret.result;
    }

    initSourceWebRTC(id: number): Observable<WebRTCInitResponse> {
        const thumbnail$ = this.http
            .put<{ result: WebRTCInitResponse; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}` + Constants.apiUrls.webrtc_init,
                {}
            )
            .pipe(share());

        thumbnail$.subscribe(
            result => {
                const data: WebRTCInitResponse = result.result;
                return data;
            },
            // eslint-disable-next-line no-console
            error => console.log("error", error)
        );

        return thumbnail$.pipe(map(r => r.result));
    }

    connectSourceWebRTC(id: number, publishPoint: string): Observable<WebRTCConnectResponse> {
        const thumbnail$ = this.http
            .put<{ result: WebRTCConnectResponse; success: boolean }>(
                Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}` + Constants.apiUrls.webrtc_connect,
                {},
                {
                    params: {
                        webrtc_publish_point: publishPoint
                    }
                }
            )
            .pipe(share());

        thumbnail$.subscribe(
            result => {
                const data: WebRTCConnectResponse = result.result;
                return data;
            },
            // eslint-disable-next-line no-console
            error => console.log("error", error)
        );

        return thumbnail$.pipe(map(r => r.result));
    }

    // Get Source Channels
    async getSourceChannels(id: number) {
        try {
            const result = await lastValueFrom(
                this.http.get<APIResponse<ChannelTypes[]>>(
                    Constants.apiUrl + Constants.apiUrls.source + "/" + id + "/channels"
                )
            );

            const channels: ChannelTypes[] = result.result?.map(channel => this.channelsService.typeChannel(channel));
            return channels;
        } catch (error) {
            return false;
        }
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isSummary default value sets to false. set to true to get All sources in summary mode (override ids)
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @param isFast default value sets to false.
     * @returns Observable resolve with an array of sources or in case of error it resolves with null and log the error to the console
     */
    getSourcesNew(
        ids: number[] = null,
        isSummary = false,
        isGetUpdatedInTheLast90Seconds = false,
        isFast = false
    ): Observable<Source[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isSummary) {
            params = params.append("summary", true);
        }
        if (isFast) {
            params = params.append("fast", true);
        }
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }

        const req$ = this.http
            .get<APIResponse<Source[]>>(Constants.apiUrl + Constants.apiUrls.source, { params })
            .pipe(share());

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SOURCES"), error);
                return of(null);
            }),
            share()
        );
    }

    public isSharedSource(source: Source): boolean {
        return Boolean(source.activeSharing?.length);
    }

    getSourceBitrates(id: number): Observable<Pid[] | null> {
        const req$ = this.http.get<APIResponse<{ name: string; values: { timestamp: number; bitrate: number }[] }[]>>(
            `${Constants.apiUrl}${Constants.apiUrls.source}/${id}${Constants.apiUrls.bitrates}`
        );
        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result.map(({ name, values }) => ({
                    name,
                    bitrates: values.map(({ timestamp, bitrate }) => ({
                        date: new Date(timestamp),
                        kbs: _.isNumber(bitrate) ? _.round(bitrate / 1000) : null
                    }))
                }));
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_SOURCES"), error);
                return of(null);
            })
        );
    }

    async insertClip(clipId: number, liveEventId: number) {
        const data = await this.http
            .put<APIResponse<{}>>(
                Constants.apiUrl +
                    Constants.apiUrls.liveevents +
                    `/${liveEventId}${Constants.apiUrls.clips}/${clipId}${Constants.apiUrls.insert}`,
                {}
            )
            .toPromise();
        if (!data.success) {
            throw new Error(data.error);
        }
        return data.result;
    }

    sourceConfigHelp(source: Source) {
        const modal = this.ngbModal.open(SourceConfigDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.source = source;
        return modal.result;
    }

    sourceTracerouteModal(source: Source) {
        const modal = this.ngbModal.open(SourceTracerouteDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.source = source;
        return modal.result;
    }

    getDisasterRecoveryState(source: Source): RecoveryState {
        if (source.primary_broadcaster_cluster_id === source.inputCluster.id) {
            if (source.inputCluster.alt_cluster_id) return RecoveryState.primary;
            else return RecoveryState.none;
        }
        return RecoveryState.alternative;
    }

    isAltPath(source: Source): boolean {
        // Check if enough data to check alt
        if (source.inputCluster === undefined) return false;
        if (!source.primary_broadcaster_cluster_id) return false;
        // Check if primary cluster matches current inputCluster
        if (source.primary_broadcaster_cluster_id === (source.inputCluster.id || source.broadcaster_cluster_id))
            return false;
        else return true;
    }

    getSourceContentAnalysisState(source: Source) {
        if (source.content_analysis) {
            return SOURCE_CONTENT_ANALYSIS.FULL;
        } else if (source.tr101_analysis) {
            return SOURCE_CONTENT_ANALYSIS.TR101_ONLY;
        } else {
            return SOURCE_CONTENT_ANALYSIS.NONE;
        }
    }

    toggleDisasterRecoveryState(source: Source, targetBroadcasterId?: number) {
        const state = this.getDisasterRecoveryState(source);
        if (state === RecoveryState.none) return;
        const targetClusterId =
            state === RecoveryState.primary
                ? source.inputCluster.alt_cluster_id
                : source.primary_broadcaster_cluster_id;
        const reqTargetBroadcasterId =
            state === RecoveryState.primary ? targetBroadcasterId : source.primary_target_broadcaster_id;

        return this.updateSource(source, {
            broadcaster_cluster_id: targetClusterId,
            target_broadcaster_id: reqTargetBroadcasterId
        });
    }
}
